@import "tailwindcss";

@theme {
  --font-sans:
    Moderat, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";

  --color-red-50: #fff6f5;
  --color-red-100: #ffe7e5;
  --color-red-200: #ffcfcc;
  --color-red-300: #ffa9a3;
  --color-red-400: #ff7c75;
  --color-red-500: #ff383b;
  --color-red-600: #ef1f2d;
  --color-red-700: #cc0014;
  --color-red-800: #99000a;
  --color-red-900: #660007;
  --color-red-950: #3d0003;

  --color-orange-50: #fffaf0;
  --color-orange-100: #fff6e0;
  --color-orange-200: #ffeac7;
  --color-orange-300: #ffd199;
  --color-orange-400: #ffad66;
  --color-orange-500: #ff843d;
  --color-orange-600: #f36012;
  --color-orange-700: #d64000;
  --color-orange-800: #ad2e00;
  --color-orange-900: #751f00;
  --color-orange-950: #471100;

  --color-yellow-50: #fffcf0;
  --color-yellow-100: #fffae5;
  --color-yellow-200: #fff6d1;
  --color-yellow-300: #ffe9a0;
  --color-yellow-400: #ffd95c;
  --color-yellow-500: #ffc933;
  --color-yellow-600: #f5ab00;
  --color-yellow-700: #c78800;
  --color-yellow-800: #996900;
  --color-yellow-900: #6b4700;
  --color-yellow-950: #332200;

  --color-blue-50: #f6fbfe;
  --color-blue-100: #e8f6fc;
  --color-blue-200: #dbf0fa;
  --color-blue-300: #bae1f6;
  --color-blue-400: #7cc9f8;
  --color-blue-500: #24afff;
  --color-blue-600: #0090f0;
  --color-blue-700: #0072c9;
  --color-blue-800: #005aa3;
  --color-blue-900: #004880;
  --color-blue-950: #002647;

  --color-turquoise-50: #f6fcfe;
  --color-turquoise-100: #e8f8fc;
  --color-turquoise-200: #d6f2fa;
  --color-turquoise-300: #c2e6f0;
  --color-turquoise-400: #a3d5e1;
  --color-turquoise-500: #82c2cf;
  --color-turquoise-600: #4da1b2;
  --color-turquoise-700: #32808f;
  --color-turquoise-800: #265c64;
  --color-turquoise-900: #044046;
  --color-turquoise-950: #003136;

  --color-green-50: #f2fdf5;
  --color-green-100: #eafcef;
  --color-green-200: #cff8dd;
  --color-green-300: #a9f6c2;
  --color-green-400: #65ec9d;
  --color-green-500: #0bd275;
  --color-green-600: #01bb64;
  --color-green-700: #01a257;
  --color-green-800: #007a41;
  --color-green-900: #00572b;
  --color-green-950: #003319;

  --color-gray-50: #fafafa;
  --color-gray-100: #f5f5f4;
  --color-gray-200: #eeeeec;
  --color-gray-300: #d8d6d4;
  --color-gray-400: #bab8b5;
  --color-gray-500: #9c9a96;
  --color-gray-600: #7e7b77;
  --color-gray-700: #605f5c;
  --color-gray-800: #4a4744;
  --color-gray-900: #3a3836;
  --color-gray-950: #2d2b29;
}
